import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useThemeContext } from "../Themes/ThemeContextProvider";
import FilterField from "./FilterField";
import PaginationElement from "./PaginationElement";

const Row = ({
  columns,
  onRowClick,
  row,
  rowIndex,
  data,
  collapsible,
  collapsibleCaller,
}) => {
  const { colors } = useThemeContext();
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <TableRow
        onClick={() => onRowClick && onRowClick(row)}
        className={`${
          onRowClick &&
          "cursor-pointer hover:bg-bgSecondary-light hover:dark:bg-bgSecondary "
        } `}
        style={{ backgroundColor: colors.bgPrimary }}
        sx={collapsible && { "& > *": { borderBottom: "unset" } }}
      >
        {columns.map((column, colIndex) => (
          <TableCell
            sx={
              rowIndex === data?.length - 1
                ? { border: "none" }
                : {
                    borderBottom: `0.5px solid ${colors.borderPrimary}`,
                  }
            }
            hidden={column?.hidden}
            key={colIndex}
          >
            <span
              style={column?.columnStyle}
              className={"text-sm  text-primary  "}
            >
              {!!column?.renderAction
                ? column.renderAction(row)
                : row[column.field]}
            </span>{" "}
          </TableCell>
        ))}{" "}
        {collapsible && (
          <TableCell
            sx={
              rowIndex === data?.length - 1
                ? { border: "none", display: "flex", justifyContent: "end" }
                : {
                    borderBottom: `0.5px solid ${colors.borderPrimary}`,
                    display: "flex",
                    justifyContent: "end",
                  }
            }
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              <div className="flex justify-end">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </div>
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      {collapsible && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns?.length + 1}
            sx={{ border: "none" }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {collapsibleCaller && collapsibleCaller(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};

const MuiTable = (props) => {
  const { colors } = useThemeContext();
  const {
    columns,
    data,
    tableHeight = "auto",
    tableTitle = "",
    searchValue = "",
    onSearchChange = () => {},
    onSearchReset,
    onPageChange = () => {},
    onRowClick,
    page,
    pages,
    hideFilter = false,
    collapsible = false,
    collapsibleCaller,
    roundedBorder = true,
  } = props;

  return (
    <div
      className={`relative top-0 h-full border-1  ${
        roundedBorder ? `rounded-lg` : ``
      } border-primary  overflow-hidden`}
    >
      {tableTitle && hideFilter && (
        <div
          className={`p-4 w-full items-center flex justify-between ${
            roundedBorder ? `rounded-lg` : ``
          } `}
        >
          <div className="text-lg  text-primary  ">{tableTitle}</div>
          {!hideFilter && (
            <div className="w-[30%]">
              <FilterField
                value={searchValue}
                onChange={onSearchChange}
                onReset={onSearchReset}
              />
            </div>
          )}
        </div>
      )}

      <div style={{ maxWidth: "100%", height: tableHeight, overflow: "auto" }}>
        <Table stickyHeader>
          {data?.length != 0 ? (
            <TableHead>
              <TableRow sx={{ background: colors.bgSecondary }}>
                {columns.map((column, index) => (
                  <TableCell
                    hidden={column?.hidden}
                    key={index}
                    style={{ width: column.width }}
                    sx={{
                      borderBottom: `1px solid ${colors.borderPrimary}`,
                      borderTop:
                        tableTitle &&
                        hideFilter &&
                        `1px solid ${colors.borderPrimary}`,
                      background: colors.bgSecondary,
                    }}
                  >
                    <span className="text-tertiary text-xs">
                      {column.title}
                    </span>
                  </TableCell>
                ))}{" "}
                {collapsible && (
                  <TableCell
                    sx={{
                      borderBottom: `1px solid ${colors.borderPrimary}`,
                      borderTop:
                        tableTitle &&
                        hideFilter &&
                        `1px solid ${colors.borderPrimary}`,
                      background: colors.bgSecondary,
                    }}
                  />
                )}
              </TableRow>
            </TableHead>
          ) : null}
          {data?.length === 0 && (
            <TableRow>
              <TableCell sx={{ border: "none" }} colSpan={columns?.length}>
                <span className="flex justify-center text-center ">
                  No results found
                </span>
              </TableCell>
            </TableRow>
          )}
          <TableBody>
            {!!data?.length &&
              data.map((row, rowIndex) => (
                <Row
                  key={row?.id || rowIndex}
                  row={row}
                  columns={columns}
                  onRowClick={onRowClick}
                  data={data}
                  rowIndex={rowIndex}
                  collapsible={collapsible}
                  collapsibleCaller={collapsibleCaller}
                />
              ))}
          </TableBody>
        </Table>
      </div>
      {pages > 1 && (
        <div className=" ">
          <PaginationElement
            page={page}
            total={pages}
            handleChangeInPagination={onPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default MuiTable;

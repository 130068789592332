import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import { useDispatch, useSelector } from "react-redux";
import { getPromptLogs } from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import { formatTimestampToDate } from "../../../../services/generalFunctions";
import { handleLoading } from "../../../../store/collectionsSlice";
import MuiTable from "../../../UI/MuiTable";
const PromptLogs = () => {
  const [logsData, setLogsData] = useState({});
  const [value, setValue] = useState([
    dayjs("2022-04-17"),
    dayjs("2022-04-21"),
  ]);
  let dateObj = new Date();
  let currentTime = dateObj.getTime();
  let yesterdayTime = currentTime - 24 * 60 * 60 * 1000;
  dateObj.setTime(yesterdayTime);
  // const [startDate, setStartDate] = useState(
  //   dayjs(moment(new Date()).local().format().toString()).add(-1, "day")
  // );
  // const [endDate, setEndDate] = useState(
  //   dayjs(moment(new Date()).local().format().toString())
  // );
  const [startDate, setStartDate] = useState(dateObj);
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const editPromptsList = useSelector(
    (state) => state.collectionsSlice?.editPromptsList
  );
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const fetchLogs = ({
    page = 1,
    start_date_in = null,
    end_date_in = null,
  }) => {
    dispatch(handleLoading(true));
    var payload = {
      prompt_id: promptData?.id,
      params: {
        page: page,
        start_date:
          (start_date_in != null ? start_date_in : startDate)?.getTime() / 1000,
        end_date:
          (end_date_in != null ? end_date_in : endDate)?.getTime() / 1000,
      },
    };
    getPromptLogs(payload)
      .then((res) => {
        console.log(res, "LOGS");
        setLogsData(res);
      })
      .catch((error) => {
        snackbar.error({ title: `${error.error && error.error.toString()}` });
      })
      .finally(() => dispatch(handleLoading(false)));
  };

  useEffect(() => {
    if (!!promptData?.id) {
      fetchLogs({ page: 1 });
    }
  }, [promptData?.id]);

  const columns = [
    {
      field: "created_at",
      title: "Date",
      renderAction: (row) => (
        <span>
          {formatTimestampToDate(row?.created_at, "DD MMM, YYYY hh:mm A")}
        </span>
      ),
    },
    {
      field: "model_type",
      title: "Model",
    },
    {
      field: "version_type",
      title: "Version",
    },
    {
      field: "input_tokens",
      title: "Input Tokens",
    },
    {
      field: "output_tokens",
      title: "Output Tokens",
    },
    {
      field: "request_cost",
      title: "Cost",
      renderAction: (row) => <span>${row?.request_cost}</span>,
    },
    {
      field: "cached",
      title: "Cached",
      renderAction: (row) => <span>{row?.cached ? "True" : "False"}</span>,
    },
    {
      field: "api_burns",
      title: "Burn",
      renderAction: (row) => <span>{row?.api_burns ? "API" : "Test"}</span>,
    },
    {
      field: "success",
      title: "Status",
      renderAction: (row) => (
        <span
          className={`${
            row?.success
              ? "text-[#067647] dark:text-[#75E0A7]"
              : "text-[#B42318] dark:text-[#FDA29B]"
          }`}
        >
          {row?.success ? "Success" : "Failed"}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="p-2" style={{ minHeight: "800px" }}>
        <div className="flex justify-between items-center py-6 ">
          {" "}
          <div>Prompt Logs</div>
          <div
            className="flex gap-2 items-center"
            style={{ minWidth: "400px" }}
          >
            <RangeDatePicker
              startDate={startDate}
              endDate={endDate}
              // minDate={new Date(2020, 0, 1)}
              // highlightToday
              singleCalendar
              maxDate={currentTime}
              onChange={(startDate, endDate) => {
                if (startDate != null) {
                  setStartDate(startDate);
                }
                if (endDate != null) {
                  setEndDate(endDate);
                }
                if (startDate != null && endDate != null) {
                  fetchLogs({
                    page: 1,
                    start_date_in: startDate,
                    end_date_in: endDate,
                  });
                }

                // alert(startDate, endDate);
              }}
            />
          </div>{" "}
        </div>
        <MuiTable
          data={logsData.items}
          columns={columns}
          page={logsData?.page}
          // tableHeight={"900px"}
          pages={logsData?.pages}
          onPageChange={(v, page) => fetchLogs({ page })}
          hideFilter={true}
          tableTitle={""}
        />
      </div>
    </>
  );
};

export default PromptLogs;

import { ReactComponent as DraftDot } from "@Images/draftDot.svg";
import { ReactComponent as PublishDot } from "@Images/publishDot.svg";
import { Minus, ThumbsDown, ThumbsUp } from "@UI/IconPack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Slider,
  Stack,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { readString } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { getAllIterationData } from "../../../../services/app/api";
import snackbar from "../../../../services/app/snackbar";
import {
  MODEL_MAX_TOKEN_COUNT,
  llmModelsIcon,
} from "../../../../services/constants";
import { isValidXML } from "../../../../services/generalFunctions";
import { handleChangEditPrompt } from "../../../../store/collectionsSlice";
import { useThemeContext } from "../../../Themes/ThemeContextProvider";

import MuiSelect from "../../../UI/MuiSelect";
import MuiTable from "../../../UI/MuiTable";
import CsvOutput from "../formatters/CsvOutput";
import JsonOutput from "../formatters/JsonOutput";
import XmlOutput from "../formatters/XmlOutput";

const formatComparedOutputInJson = (res) => {
  if (res?.output?.items?.response[0]["data"].length) {
    try {
      let promptOutput = {};
      if (res?.output?.items?.response[0]["data"]?.length === 1) {
        promptOutput = JSON.parse(res?.output?.items?.response[0]["data"][0]);
      } else if (res?.output?.items?.response[0]["data"]?.length > 1) {
        promptOutput = res?.output?.items?.response[0]["data"].map((item) =>
          JSON.parse(item)
        );
      }
      return promptOutput;
    } catch (error) {
      snackbar.error({
        title: `Response not in valid json Formate`,
      });
      return res?.output?.items?.response[0]["data"][0];
    }
  }
};
const formatComparedOutputInCsv = (res) => {
  try {
    let toJson = readString(res?.output?.items?.response[0]["data"][0], {});
    let arrays = toJson?.data;
    let keys = arrays[0];
    const result = [];
    if (new Set(keys).size !== keys.length) {
      throw "Invalid CSV format";
    }
    for (let i = 1; i < arrays.length; i++) {
      if (keys.length !== arrays[i].length) {
        throw "Invalid CSV format";
      }
      const obj = {};
      for (let j = 0; j < keys.length; j++) {
        obj[keys[j]] = arrays[i][j];
      }
      result.push(obj);
    }
    return result;
  } catch (error) {
    console.log(error);
    return res?.output?.items?.response[0]["data"][0];
  }
};
const formatComparedOutputInXml = (res) => {
  const isValid = isValidXML(res?.output?.items?.response[0]["data"][0]);
  if (!isValid) {
    snackbar.error({
      title: "Invalid Xml format",
    });
  }
  return res?.output?.items?.response[0]["data"][0];
};

export const formatOutputOfIteration = (iterationData) => {
  if (iterationData?.iteration_data?.length > 0) {
    const outputFunctions = {
      json: formatComparedOutputInJson,
      csv: formatComparedOutputInCsv,
      xml: formatComparedOutputInXml,
    };
    const opFormattedIterationData = iterationData?.iteration_data?.map(
      (iteration) => {
        let output =
          outputFunctions[iteration?.output?.output_format || "json"](
            iteration
          );
        let data = {
          ...iteration,
          promptOutput: output,
          output_format: iteration?.output?.output_format,
        };
        return data;
      }
    );

    return { ...iterationData, iteration_data: opFormattedIterationData };
  }
};

const Label = ({ className = "", children }) => (
  <p className={`   ${className}`}>{children}</p>
);
const Value = ({ className = "", children }) => (
  <p className={`   ${className}`}>{children}</p>
);
var operations = {
  "<": function (operand1, operand2) {
    return operand1 < operand2;
  },
  "<=": function (operand1, operand2) {
    return operand1 <= operand2;
  },
  ">": function (operand1, operand2) {
    return operand1 > operand2;
  },
  ">=": function (operand1, operand2) {
    return operand1 >= operand2;
  },
  "==": function (operand1, operand2) {
    return operand1 == operand2;
  },
};
const getLogo = (item1, item2, operation) => {
  return operations["=="](item1, item2) ? (
    <Minus />
  ) : operations[operation](item1, item2) ? (
    <ThumbsUp />
  ) : (
    <ThumbsDown />
  );
};

export const AverageResult = ({ data = {}, data1 = {} }) => {
  return (
    <Stack gap={2}>
      <div
        className="flex justify-between text-md-medium"
        style={{
          color:
            data?.average_response_time < data1?.average_response_time
              ? "#47CD89"
              : "",
        }}
      >
        <Label>Average response time:</Label>
        <Value>
          <div className="flex gap-2 items-center">
            {Math.floor(data?.average_response_time * 1000) / 1000} Sec
            {getLogo(
              data?.average_response_time,
              data1?.average_response_time,
              "<="
            )}
          </div>
        </Value>
      </div>
      <div
        className="flex justify-between text-md-medium"
        style={{
          color:
            data?.average_input_token < data1?.average_input_token
              ? "#47CD89"
              : "",
        }}
      >
        <Label>Average input token:</Label>
        <Value>
          <div className="flex gap-2 items-center text-md-medium">
            {data?.average_input_token}

            {getLogo(
              data?.average_input_token,
              data1?.average_input_token,
              "<="
            )}
          </div>
        </Value>
      </div>
      <div
        className="flex justify-between"
        style={{
          color:
            data?.average_output_token < data1?.average_output_token
              ? "#47CD89"
              : "",
        }}
      >
        <Label>Average output token:</Label>
        <Value>
          <div className="flex gap-2 items-center text-md-medium">
            {data?.average_output_token}

            {getLogo(
              data?.average_output_token,
              data1?.average_output_token,
              "<="
            )}
          </div>
        </Value>
      </div>
      <div
        className="flex justify-between"
        style={{
          color: data?.average_cost < data1?.average_cost ? "#47CD89" : "",
        }}
      >
        <Label>Average cost:</Label>
        <Value>
          <div className="flex gap-2 items-center text-md-medium">
            {data?.average_cost}

            {getLogo(data?.average_cost, data1?.average_cost, "<=")}
          </div>
        </Value>
      </div>
      <div
        className="flex justify-between"
        style={{
          color: data?.failure < data1?.failure ? "#47CD89" : "",
        }}
      >
        <Label>Total failure:</Label>
        <Value>
          <div className="flex gap-2 items-center text-md-medium">
            {data?.failure} {getLogo(data?.failure, data1?.failure, "<=")}
          </div>
        </Value>
      </div>
    </Stack>
  );
};

const CompareData = ({ compare, index }) => {
  const templateRef = useRef(null);
  const [errors, setErrors] = useState({});
  const { colors, mode } = useThemeContext();
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList } = newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};
  const dispatch = useDispatch();
  const [editScreen, setEditScreen] = useState(true);
  const [usedModelVersions, setUsedModelVersions] = useState([]);
  const [LLMVersions, setLLMVersions] = useState(compare?.modelsVersionsList);

  const llmModelName = compare?.aiModelsList?.find(
    (model) => model?.id === compare?.llmModel
  )?.name;

  const callIterations = ({ page = 1 }) => {
    getAllIterationData({
      comparison_id: promptData?.compareResponse?.id,
      model_compare_id: compare?.id,
      params: {
        page,
        limit: promptData?.iteration_cnt || 10,
      },
    })
      .then((res) => {
        let data = {
          ...promptData,
          iterationResponse: promptData?.iterationResponse?.map((iter) =>
            iter?.id === res?.id ? formatOutputOfIteration(res) : iter
          ),
        };
        dispatch(handleChangEditPrompt(data));
      })
      .catch((error) => {
        console.log(error);
        snackbar.error({
          title: `${error.error && error.error.toString()}`,
        });
      })
      .finally(() => {});
  };

  useEffect(() => {
    let modelVersionIds = [];
    for (let i = 0; i < promptData?.compare_data?.length; i++) {
      if (i !== index && !!promptData?.compare_data[i]?.model_version_id) {
        modelVersionIds.push(promptData?.compare_data[i]?.model_version_id);
      }
    }
    setUsedModelVersions(modelVersionIds);
  }, [promptData?.compare_data]);

  const costWin = promptData?.compareResultResponse?.cost_win_model;
  const timeWin = promptData?.compareResultResponse?.time_win_model;
  const averageData = promptData?.compareResultResponse?.compare_data;
  const iterationData =
    !!promptData?.iterationResponse?.length > 0
      ? promptData?.iterationResponse?.find(
          (iteration) => iteration?.id === compare?.id
        )
      : {};

  const handleChange = (field, value) => {
    let data = {
      ...promptData,
      compare_data: promptData?.compare_data?.map((c, i) =>
        i === index
          ? {
              ...c,
              [field]: value,
              model_version_id:
                field === "llmModel"
                  ? ""
                  : field === "model_version_id"
                  ? value
                  : c?.model_version_id,
            }
          : c
      ),
    };
    if (field === "llmModel" || field === "model_version_id") {
      data["compareResponse"] = {};
      data["compareResultResponse"] = {};
      data["iterationResponse"] = [];
    }
    if (field === "llmModel") {
      let versions = compare?.aiModelsList?.find(
        (model) => model?.id === value
      )?.versions;
      data["modelsVersionsList"] = versions;
      setLLMVersions(versions);
    }
    dispatch(handleChangEditPrompt(data));
  };

  const columns = [
    {
      field: "cost",
      title: "Cost",
      renderAction: (row) => (
        <span>
          <p className="text-sm-medium">${row?.cost}</p>
        </span>
      ),
    },
    {
      field: "response_time",
      title: "Response time",
      renderAction: (row) => (
        <span>
          <p className="text-sm-medium">
            {Math.floor(row?.response_time * 1000) / 1000} Sec.
          </p>
        </span>
      ),
    },
    {
      field: "failure",
      title: "Status",
      renderAction: (row) => (
        <span>
          {row?.failure ? (
            <div className="border-2 w-fit rounded-lg px-[6px] py-[2px] flex items-center gap-1 border-primary">
              <DraftDot />
              Fail
            </div>
          ) : (
            <div className="border-2 w-fit rounded-lg px-[6px] py-[2px] flex items-center gap-1 border-primary">
              <PublishDot />
              Success
            </div>
          )}
        </span>
      ),
    },
  ];

  const collapsibleCaller = (row) => {
    return (
      <div className="my-4">
        {row?.output_format === "json" && <JsonOutput promptData={row} />}
        {row?.output_format === "csv" && <CsvOutput promptData={row} />}
        {row?.output_format === "xml" && <XmlOutput promptData={row} />}
      </div>
    );
  };

  return (
    <div className="w-1/2 px-5 last:border-none border-r-1 border-primary bg-secondary border-1 rounded-lg border-secondary p-5">
      {!promptData?.comparisonProgress && !averageData ? (
        <Stack
          gap={2}
          display={promptData?.model_type === "Text" ? "block" : "none"}
        >
          <Box
            display={"flex"}
            // alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
            mb={2}
          >
            {/* <FormLabel
            htmlFor="temperature"
            sx={{ color: colors.textTertiary, fontSize: 14 }}
          >
            Model & Version:
          </FormLabel> */}

            <div className="text-md-medium">Model & Version:</div>
            <div className="w-[35%] flex flex-col  gap-4">
              <div className="w-full">
                <MuiSelect
                  label={"Model"}
                  menuItems={
                    compare?.aiModelsList?.filter(({ versions }) =>
                      versions?.some(
                        ({ model_type }) =>
                          model_type === promptData?.model_type
                      )
                    ) || []
                  }
                  value={compare?.llmModel}
                  onChange={(e) => handleChange("llmModel", e.target.value)}
                  error={errors?.llmModel}
                  onFocus={() =>
                    setErrors((ini) => ({ ...ini, llmModel: false }))
                  }
                  valueKey={"id"}
                  labelKey={"name"}
                  selectClassName={"bg-primary"}
                />
              </div>
              <div className="w-full">
                <MuiSelect
                  label={"Version"}
                  menuItems={LLMVersions?.filter(
                    ({ id }) => !usedModelVersions?.includes(id)
                  )}
                  value={compare?.model_version_id}
                  onChange={(e) =>
                    handleChange("model_version_id", e.target.value)
                  }
                  disabled={!compare?.llmModel}
                  error={errors?.model_version_id}
                  onFocus={() =>
                    setErrors((ini) => ({
                      ...ini,
                      model_version_id: false,
                    }))
                  }
                  valueKey={"id"}
                  labelKey={"name"}
                  selectClassName={"bg-primary"}
                />
              </div>
            </div>
          </Box>

          <div className="border-t-1 border-primary my-5" />
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
            my={2}
          >
            <div
              htmlFor="temperature"
              sx={{ color: colors.textTertiary }}
              className="text-md-medium"
            >
              Temperature:
            </div>

            <div className="w-[65%]">
              <Slider
                defaultValue={0.5}
                step={0.1}
                max={1}
                min={0}
                aria-label="Temperature"
                name="temperature"
                valueLabelDisplay="auto"
                onChange={(_, v) => handleChange("temperature", v)}
                value={compare?.temperature}
              />
            </div>
          </Box>

          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"space-between"}
          >
            <div
              className="text-md-medium"
              htmlFor="token"
              sx={{ color: colors.textTertiary, fontSize: 14 }}
            >
              Maximum tokens to use:
            </div>
            <div className="w-[65%]">
              <Slider
                defaultValue={3000}
                step={1}
                max={MODEL_MAX_TOKEN_COUNT[llmModelName] || 10000}
                min={1}
                aria-label="token"
                name="max_token"
                valueLabelDisplay="auto"
                value={compare?.max_tokens}
                onChange={(_, v) => handleChange("max_tokens", v)}
              />
            </div>
          </Box>
        </Stack>
      ) : null}
      {!!averageData && (
        <div>
          <div className="text-md-medium flex  gap-2 items-center">
            {llmModelsIcon("40px")[llmModelName]}
            <div>
              {
                compare?.aiModelsList?.filter((item) => {
                  return item.id == compare?.llmModel;
                })?.[0]?.name
              }
            </div>
            <div>
              {
                compare?.modelsVersionsList?.filter((item) => {
                  return item.id == compare?.model_version_id;
                })?.[0]?.name
              }
            </div>
          </div>
          <div className="border-t-1 border-primary my-5" />
        </div>
      )}
      {promptData?.comparisonProgress && (
        <div>
          <div className="p-10 flex justify-center">
            <CircularProgress />
          </div>
          <p className="text-sm-medium text-center">
            Please wait until the comparison is complete.
          </p>
        </div>
      )}
      <div className="my-5">
        {!!averageData && (
          <Accordion
            defaultExpanded
            sx={
              timeWin?.id === compare?.model_version_id
                ? {
                    // backgroundColor: mode === "dark" ? "#053321" : "#ECFDF3",
                    // border:
                    //   mode === "dark"
                    //     ? "1px solid #085D3A"
                    //     : "1px solid #ABEFC6",
                    boxShadow: "none",
                    backgroundColor: colors.bgPrimary,
                    backgroundImage: "none",
                  }
                : {
                    backgroundColor: colors.bgPrimary,
                    boxShadow: "none",
                    backgroundImage: "none",
                  }
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <div className="text-lg-semibold">Result</div>
            </AccordionSummary>
            <AccordionDetails>
              <AverageResult
                data={averageData[index]}
                data1={averageData[index == 0 ? 1 : 0]}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      {iterationData?.iteration_data?.length > 0 && (
        <div className={`w-full mt-5 `}>
          <div className="text-md-medium py-3">Iteration</div>
          <MuiTable
            data={iterationData.iteration_data}
            columns={columns}
            page={iterationData?.page}
            pages={iterationData?.pages}
            onPageChange={(v, page) => callIterations({ page: page })}
            // tableTitle={"Iteration"}
            hideFilter={true}
            collapsible={true}
            collapsibleCaller={collapsibleCaller}
          />
        </div>
      )}
    </div>
  );
};

export default CompareData;

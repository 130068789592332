import { formatNumber } from "@Services/generalFunctions";
import { Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";
import { FileTypeIcon } from "../../../UI/IconPack";
import CsvOutput from "../formatters/CsvOutput";
import ImageOutput from "../formatters/ImageOutput";
import JsonOutput from "../formatters/JsonOutput";
import OutputFormat from "../formatters/OutputFormat";
import XmlOutput from "../formatters/XmlOutput";
const PromptOutput = (props) => {
  const { outputFormatError, outputFormatOnFocus, isReadOnlyMode } = props;
  const newPromptsData = useSelector((state) => state.collectionsSlice);
  const { editPromptsList } = newPromptsData;
  const promptData =
    editPromptsList.filter((prompt) => prompt?.isActiveTab)[0] || {};

  const Hr = () => <div className="border-b-1 my-3  border-primary" />;
  const Title = ({ children, className = "" }) => (
    <p
      className={`text-sm font-medium text-tertiary uppercase pb-5 ${className}`}
    >
      {children}
    </p>
  );
  const Label = ({ className = "", children }) => (
    <p className={`text-sm  text-primary  ${className}`}>{children}</p>
  );
  const Value = ({ className = "", children }) => (
    <p className={`text-2xl text-primary  ${className}`}>{children}</p>
  );
  return (
    <>
      <Grid container px={10} py={2} spacing={2}>
        {!isReadOnlyMode && (
          <>
            <Grid item md={3.5}>
              <div className={"text-secondary font-semibold text-sm"}>
                Response format
              </div>
              <div className={"text-tertiary text-sm"}>
                Set output format to get results accordingly
              </div>
            </Grid>
            <Grid item md={8.5}>
              <OutputFormat
                error={outputFormatError}
                onFocus={outputFormatOnFocus}
              />
            </Grid>
          </>
        )}

        {!!promptData?.promptOutput && (
          <>
            {!isReadOnlyMode && (
              <div className="w-full border-t-1 border-secondary mt-4" />
            )}
            <Grid item md={3.5}>
              <div className={"text-secondary font-semibold text-sm"}>
                Response
              </div>
              <div className={"text-tertiary text-sm mb-10"}>
                Response for given prompt
              </div>{" "}
              {promptData.response_state_result == "success" ? (
                <div className="">
                  <div className=" grid grid-cols-2 gap-2">
                    <div>
                      <Label>Time invoked</Label>
                      <Value>{promptData?.prompt_response_time}</Value>
                    </div>{" "}
                    <div>
                      <Label>Tokens consumed</Label>
                      <div className="flex items-end gap-1">
                        <Value>
                          {formatNumber(promptData?.prompt_token || 0)}
                        </Value>{" "}
                        <span className="text-base pb-[2px]">
                          ${promptData?.prompt_cost} cost
                        </span>
                      </div>
                    </div>
                    <div>
                      <Label>Number of Input tokens</Label>
                      <Value>{promptData?.prompt_input_tokens}</Value>
                    </div>
                    <div>
                      <Label>Number of O/p tokens</Label>
                      <div className="flex items-end gap-1">
                        <Value>{promptData?.prompt_output_tokens}</Value>{" "}
                        {/* <span className="text-base pb-[2px]">Seconds</span> */}
                      </div>
                    </div>
                    {/* <div>
                    <Label>Errors/Issues</Label>
                    <div className="flex items-end gap-2">
                      <Value>{(11111 && formatNumber(11111)) || "0"}</Value>
                    </div>
                  </div> */}
                  </div>
                </div>
              ) : null}
              {promptData.response_state_result == "recall" ? (
                <div>
                  <div className={"text-tertiary text-sm my-2"}>
                    Calculating prompt execution states
                  </div>
                  <Skeleton variant="rectangular" width={"100%"} height={130} />
                </div>
              ) : null}
              {/* <div className="mt-8 bg-black p-6 rounded-lg">
                <div className="flex gap-2 items-center my-2">
                  <div className={"text-secondary font-semibold text-sm"}>
                    Used Tokens
                  </div>
                  <div className={"text-tertiary text-sm"}>
                    {promptData?.prompt_token}
                  </div>{" "}
                </div>
                <div className="flex gap-2 items-center my-2">
                  <div
                    className={
                      "text-tertiary text-secondary font-semibold text-sm"
                    }
                  >
                    Input Tokens
                  </div>
                  <div className={"text-tertiary text-sm"}>
                    {promptData?.prompt_input_tokens}
                  </div>{" "}
                </div>
                <div className="flex gap-2 items-center my-2">
                  <div className={"text-secondary font-semibold text-sm"}>
                    Output Tokens
                  </div>
                  <div className={"text-tertiary text-sm"}>
                    {promptData?.prompt_output_tokens}
                  </div>{" "}
                </div>
              </div> */}
            </Grid>
            <Grid item md={8.5}>
              <div className={`relative`}>
                <div>
                  {promptData?.content_type === "Image" && (
                    <ImageOutput promptData={promptData} />
                  )}
                  {promptData?.content_type === "Text" &&
                    promptData?.output_format === "json" && (
                      <JsonOutput promptData={promptData} />
                    )}
                  {promptData?.content_type === "Text" &&
                    promptData?.output_format === "csv" && (
                      <CsvOutput promptData={promptData} />
                    )}
                  {promptData?.content_type === "Text" &&
                    promptData?.output_format === "xml" && (
                      <XmlOutput promptData={promptData} />
                    )}
                </div>
              </div>
            </Grid>
          </>
        )}
      </Grid>

      {isReadOnlyMode && !promptData?.promptOutput && (
        <div className="relative flex items-center justify-center ">
          <FileTypeIcon />
          <div className="absolute bottom-32 text-base text-center  text-primary">
            Run prompt to see response
          </div>
        </div>
      )}
    </>
  );
};

export default PromptOutput;

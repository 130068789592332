import React from 'react';

const Debounce = (func, time) => {
    let timer;
    return function (...args) {
        const contex = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply(contex, args);
        }, time);
    };
};

export default Debounce;

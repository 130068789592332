const FilledTextField = (props) => {
  const {
    label = "",
    style,
    className,
    error,
    helperText = "",
    endIcon = null,
    startIcon = null,
    startButton = null,
    endButton = null,
    ...rest
  } = props;
  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={`filledInput${label}`}
          className="text-secondary text-sm font-medium mb-8"
        >
          {label}
        </label>
      )}
      <div className="relative flex items-center mt-1">
        {startButton && (
          <div className="absolute left-[1px] ">{startButton}</div>
        )}
        {startIcon && <div className="absolute left-2 ">{startIcon}</div>}
        <input
          id={`filledInput${label}`}
          style={{
            outline: "none",
            padding: endIcon
              ? "10px 40px 10px 14px"
              : startIcon
              ? "10px 14px 10px 40px"
              : "10px 14px",
            fontSize: "14px",
            width: "100%",
            borderWidth: "1.8px",
            ...style,
          }}
          className={`bg-primary  text-primary focus:border-brandPrimary rounded-lg ${className} ${
            error
              ? "border-error-light dark:border-error"
              : "dark:bg-bgPrimary border-primary hover:border-black hover:dark:border-white"
          } `}
          {...rest}
        />

        {endIcon && <div className="absolute right-2">{endIcon}</div>}
        {endButton && <div className="absolute right-[1px]">{endButton}</div>}
      </div>
      <p className="text-[10px] text-error min-h-3">{helperText}</p>
    </div>
  );
};

export default FilledTextField;
